import axios from 'axios';

import { setEndpointError } from '../store/modules/endpointErrorSave/actions';

import { store } from '../../src/store/';

let api = null;

async function errorHandler(error) {
  const config = error.response.config;

  if(
    error.response.status >= 400
    &&
    error.response.status != 401
    &&
    error.response.status < 599
  ){
    if(
      error.response.status === 400 
      && error.response
      && error.response.data
      && error.response.data.Details 
      && error.response.data.Details.InnerException === null 
      && error.response.data.Details.Message 
      && error.response.data.Details.Message.length > 0
      && (
        error.response.data.Details.Message === 'Infelizmente não é mais possível o envio de tokens, por favor, aguarde um prazo de 60 min antes de tentar novamente.'
        ||
        error.response.data.Details.Message === 'Por favor verifique os dados e tente novamente mais tarde.'
      )
      && !error.response.data.Details.ActualValue
    ){
      return Promise.reject(error)
    }

    if(
      error.response.status === 400 
      && error.response
      && error.response.data
      && error.response.data.Message
      && error.response.data.Message.length > 0
      && (
        error.response.data.Message === 'Infelizmente não é mais possível o envio de tokens, por favor, aguarde um prazo de 60 min antes de tentar novamente.'
        ||
        error.response.data.Message === 'Por favor verifique os dados e tente novamente mais tarde.'
      )
    ){
      return Promise.reject(error)
    }

    setTimeout(async () => {      
      let errorToSend = null;

      if(error){
        errorToSend = error.response 
        delete errorToSend.config['headers']
        delete errorToSend.request['_performanceLogger']
        delete errorToSend['headers']
        delete errorToSend['config']
        errorToSend = JSON.stringify(errorToSend)
      }

      const toSend = {
        ...store.getState().endpointErrorSave.data,
        error: errorToSend,
        files: [],
      }

      try {
        await axios.post('https://apidev.nicbrain.com.br/api/User/ReportProblems', toSend);

        store.dispatch(setEndpointError({
          ...store.getState().endpointErrorSave.data,
          show: true,
        }));
      } catch(err) {
        console.log('ReportProblems', err)
      }
    }, 500)
  }

  return Promise.reject(error)
}

if (
  window.location.href.split('//')[1].substr(0, 25) == 'devqrcode.nicbrain.com.br' ||
  window.location.href.split('//')[1].substr(0, 35) == 'dev-qrcode-nicbrain.firebaseapp.com' ||
  window.location.href.split('//')[1].substr(0, 27) == 'dev-qrcode-nicbrain.web.app' 
  || window.location.href.substr(0, 17) == 'http://localhost:'
) {
  api = axios.create({ 
    baseURL: 'https://apidev.nicbrain.com.br/api/',
  });
}

if (
  window.location.href.split('//')[1].substr(0, 22) == 'qrcode.nicbrain.com.br' ||
  window.location.href.split('//')[1].substr(0, 31) == 'qrcode-nicbrain.firebaseapp.com' ||
  window.location.href.split('//')[1].substr(0, 23) == 'qrcode-nicbrain.web.app'
) {
  api = axios.create({
    baseURL: 'https://api.nicbrain.com.br/api/',
  });
}

function saveRequest(config) {
  store.dispatch(setEndpointError({
    show: false,
    title: 'Base: ' + config.baseURL + ' - Endpoint: ' + config.url,
    description: JSON.stringify(config.method === 'post' ? config.data : {}),
    name: "Sistema",
    subModuleId: '0',
    email: "kobel@kobel.com.br",
  }));

  return config
}

api.interceptors.request.use(config => saveRequest(config), error => Promise.reject(error));
api.interceptors.response.use(response => response, async error => await errorHandler(error));

export default api;
